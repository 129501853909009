import { MatDialog } from '@angular/material/dialog';

export { renderVisualText } from './pipes/markdown.pipe';

/**
 * Wait for a speficied amount of time to pass
 * @param ms time in ms to sleep for
 * @returns
 */
export const sleep = ms => new Promise(r => setTimeout(r, ms));

export const exportJson = (name: string, data: Object) => {
    const a = document.createElement("a");

    const text = JSON.stringify(data, (key, val) => key.startsWith('_') ? undefined : val);

    const file = new Blob([text], { type: "application/json" });
    a.href = URL.createObjectURL(file);
    a.download = name;
    a.click();
    a.remove();
}

export const openDataInMonacoPopup = async (matDialog: MatDialog, data: Object) => {
    const VscodeComponent = await import("@dotglitch/ngx-common").then(m => m.VscodeComponent);

    const d = matDialog.open(VscodeComponent, {
        width: "90vw",
        height: "90vh"
    });
    d.componentInstance.tabSize = 4;
    d.componentInstance.language = "json";
    d.componentInstance.code = JSON.stringify(data, null, 4);

    return d;
}
